<template>
  <!-- HERO PRICING -->
  <v-container class="bg-transparent mb-4 mt-16">
    <v-row class="mt-4">
      <v-col cols="12 d-flex justify-center">
        <h1 class="poppins600 text-typo text-center">
          {{ isLanding ? "Cennik" : "Wybierz plan idealny dla Twoich potrzeb" }}
        </h1>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="12" lg="4" v-for="plan in plans" :key="plan.title">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-6 pt-6 pb-4 text-center">
            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              class="
                border-radius-xl
                font-weight-bolder
                px-3
                py-3
                badge-font-size
                ms-auto
                text-dark
              "
              color="#e9ecef"
              >{{ plan.title }}</v-btn
            >

            <h1 v-bind:class="`text-h2 text-typo font-weight-bold mt-1 `">
              <span v-bind:class="` mr-2 ${plan.discount>0?'text-decoration-line-through':''}`">{{ plan.price }}<small>zł</small> </span><span v-if="plan.discount>0">{{ Math.round((plan.price*(1-(plan.discount/100)))*100)/100 }}<small>zł</small></span>
            </h1>

            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              v-if="plan.discount && plan.discount>0"
              :style="'color: white !important;'"
              class="
                border-radius-xl
                font-weight-bolder
                px-3
                py-3
                badge-font-size
                ms-auto
                text-dark
              "
              color="#ff6440"
              >Promocja -{{ Math.round(plan.discount) }}%</v-btn
            >

            <v-list class="py-6 text-lg-start text-center" color="transparent">
              <v-list-item
                v-for="item in plan.features"
                :key="item.title"
                class="px-0 py-0"
              >
                <v-list-item-avatar
                  :size="24"
                  color="#fff"
                  class="my-0 me-4 shadow"
                  :class="`bg-gradient-` + item.color"
                >
                  <v-icon size="9" class="text-white">{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title
                    v-text="item.title"
                    class="text-body-1 ls-0 text-muted"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                btn-primary
                py-3
                text-xs
                px-6
                ls-0
                w-100
                border-radius-md
              "
              :loading="planLoading === plan.planId"
              @click="handleClick(plan.planId)"
              :class="`bg-gradient-` + plan.buttonColor"
            >
              {{ userHasActivePlan?'Przedłuż plan':plan.buttonText }}
              <v-icon size="12">fas fa-arrow-right ms-2</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2" v-if="!preorder && $route.path !='/' && !userHasActivePlan">
      <v-col class="d-flex justify-center">
          <h4 class="text-gray cursor-pointer" @click="goToDashboard()">Kontynuuj bez usługi premium</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import qs from "qs";

export default {
  name: "PaymentPlans",
  data() {
    return {
      planLoading: "",
      stripe:undefined,
      preorder: false,
      blackFriday: true,
      birthday: true,
      otherPromo: true,
    };
  },
  props: {
    isLanding: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    goToDashboard(){
      this.$router.push("/dashboard");
    },
    setLoading(val = "") {
      this.planLoading = val;
    },
    handleClick(planId) {
      if (this.isLanding) {
        localStorage.setItem("vive-chosen-plan", planId);
        this.$router.push("/signup");
      } else {
        this.handleStripe(planId);
      }
    },
    handleStripe(planId) {
      this.setLoading(planId);
      this.$axios({
        method: "post",
        url: "/api/payment/get-session",
        data: qs.stringify({
          planId: planId,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            this.stripe.then((stripe) => {
              stripe
                .redirectToCheckout({
                  sessionId: response.data.session.id,
                })
                .then(function (result) {
                  console.log(result);
                })
                .catch(function (error) {
                  this.setLoading();
                  console.error(error);
                });
            });
          } else {
            this.setLoading();
          }
        })
        .catch(() => {
          this.setLoading();
          //this.setError("Błędne dane logowania!");
        });
    },
  },
  computed: {
    plans() {
      if (this.preorder) {
        return this.$store.state.preorderPlans
      } else if (this.blackFriday) {
        return this.$store.state.blackFridayPlans
      } else if (this.birthday) {
        return this.$store.state.birthdayPlans
      } else if (this.otherPromo) {
        return this.$store.state.otherPromoPlans
      } else {
        return this.$store.getters.getPaymentPlans
      }
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
  },
  created(){
    this.stripe = loadStripe(this.$stripePublicKey);
    this.preorder = this.$store.getters.isPreorder;
    this.blackFriday = this.$store.getters.isBlackFriday;
    this.birthday = this.$store.getters.isBirthday;
    this.otherPromo = this.$store.getters.isOtherPromo;
  }
};
</script>

<style scoped>
.text-decoration-line-through{
  text-decoration-thickness: 4px !important;
}
</style>